import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  DialogActions,
} from "@material-ui/core";
import { useState } from "react";
import { Button, Loading, useUnselectAll, useTranslate } from "react-admin";
import { useNotify } from "ra-core";

const GET_CLASSES = gql`
  query Classes($where: ClassWhereInput) {
    classes(where: $where) {
      id
      name
    }
  }
`;

const ADD_STUDENT_TO_CLASS = gql`
  mutation AddStudentsToClass($classId: Int!, $students: [StudentInput!]!) {
    addStudentsToClass(classId: $classId, students: $students) {
      id
    }
  }
`;

function BulkAddClassButton({ selectedIds, organizationId, ...others }) {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [selectedClassId, setSelectedClassId] = useState("");
  const unselectAll = useUnselectAll();
  const { loading: classLoading, data: classData } = useQuery(GET_CLASSES, {
    variables: { where: { organizationId: { equals: organizationId } } },
  });
  const [addStudentsToClass] = useMutation(ADD_STUDENT_TO_CLASS, {
    onCompleted() {
      unselectAll("User");
    },
    onError(error) {
      notify(`${error}`, "warning");
    },
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setSelectedClassId(event.target.value);
  };

  const handleConfirmClick = () => {
    addStudentsToClass({
      variables: {
        classId: selectedClassId,
        students: selectedIds.map((id) => ({ userId: id })),
      },
    });
    setOpen(false);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  if (classLoading) {
    return <Loading />;
  }

  return (
    <>
      <Button label="addInClass" onClick={handleClick} />
      <Dialog className="dialog" open={open}>
        <DialogTitle>{translate("bulkAddClassMessage")}</DialogTitle>
        <DialogContent>
          {classData?.classes && (
            <Select value={selectedClassId} onChange={handleChange} fullWidth>
              {classData.classes.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleConfirmClick} color="primary">
            {translate("confirm")}
          </MuiButton>
          <MuiButton onClick={handleCancelClick} color="primary">
            {translate("cancel")}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BulkAddClassButton;
