import { Datagrid, List, TextField } from "react-admin";

function PermissionList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name" label="name" />
      </Datagrid>
    </List>
  );
}

export default PermissionList;
