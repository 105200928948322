import { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions, makeStyles } from "@material-ui/core";
import { Button } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import BulkDeleteButton from "./BulkDeleteButton";
import SingleDeleteButton from "./SingleDeleteButton";

const useStyles = makeStyles({
  root: {
    color: "red",

    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
});

function DeleteButton(props) {
  const classes = useStyles();
  const { isBulk, handleBulkDelete } = props;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={classes.root} startIcon={<DeleteIcon />} label="delete" onClick={handleClickOpen} />
      <Dialog
        PaperProps={{
          style: {
            padding: "30px",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>Are you sure you want to delete selected users?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label="cancel" />
          {isBulk ? (
            <BulkDeleteButton handleClose={handleClose} onBulkDelete={handleBulkDelete} {...props} />
          ) : (
            <SingleDeleteButton handleClose={handleClose} {...props} />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteButton;
