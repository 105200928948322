import { ApolloClient, createHttpLink, from, fromPromise, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Cookies } from "react-cookie";
import getNewToken from "../utils/refreshToken";
import { getBackendHost, getAuthHost } from "./server";

const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: getBackendHost(),
});

const authLink = setContext((_, { headers }) => {
  const token = cookies.get("idToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors && graphQLErrors[0].message && graphQLErrors[0].message.includes("jwt expired")) {
    return fromPromise(getNewToken()).flatMap((result) => {
      try {
        const { token } = result.data.refreshToken;
        cookies.set("idToken", token, {
          path: "/",
          domain: process.env.NODE_ENV === "production" ? ".youcodia.life" : "localhost",
          maxAge: 1000 * 60 * 60 * 60 * 24 * 7,
          secure: process.env.NODE_ENV === "production",
        });
        const oldHeaders = operation.getContext().headers;
        // modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${token}`,
          },
        });
        return forward(operation);
      } catch (e) {
        cookies.remove("idToken", { path: "/", domain: process.env.NODE_ENV === "production" && ".youcodia.life" });
        cookies.remove("refreshToken", {
          path: "/",
          domain: process.env.NODE_ENV === "production" && ".youcodia.life",
        });
        localStorage.clear();
        window.location.href = `${getAuthHost()}/login?redirect_uri=${encodeURIComponent(`${window.location.href}`)}`;
        throw new Error(result.errors ? result.errors[0].message : e.message);
      }
    });
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
