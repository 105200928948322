import {
  BooleanInput,
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function ClassCreate(props) {
  const organization = useSelector((state) => state.organization.value);
  const classes = useStyles();
  const Actions = () => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
      </TopToolbar>
    );
  };
  const transform = (data) => {
    const { students, organization } = data;

    let newStudents = [];
    if (students?.length > 0) {
      newStudents = students.map((studentId) => ({
        userId: studentId,
      }));
    }
    const org = organization.id !== 0 ? organization.id : organization;
    return { ...data, students: newStudents, organizationId: org };
  };

  return (
    <Create {...props} transform={transform} actions={<Actions />} mutationMode="optimistic">
      <SimpleForm>
        <TextInput source="name" validate={required()} label="name" />
        <TextInput source="year" validate={required()} label="year" />
        <BooleanInput source="active" validate={required()} label="active" />
        {organization.id === 0 && (
          <ReferenceInput source="organization" reference="Organization">
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <ReferenceArrayInput source="students" reference="User" label="students">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export default ClassCreate;
