export const getBackendHost = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return process.env.REACT_APP_BACKEND || "http://localhost:5300/graphql";
    case "production":
      if (process.env.REACT_APP_ENV === "staging") {
        return "https://admin.staging.youcodia.life/graphql";
      } else {
        return "https://admin.youcodia.life/graphql";
      }
    default:
      return "http://localhost:4100/graphql";
  }
};

export const getAuthHost = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return "http://localhost:3000";
    case "production":
      if (process.env.REACT_APP_ENV === "staging") {
        return "https://login.staging.youcodia.life";
      } else {
        return "https://login.youcodia.life";
      }
    default:
      return "http://localhost:3000";
  }
};

// export default { getBackendHost, getAuthHost };
