import {
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
  useEditController,
} from "react-admin";
import { useSelector } from "react-redux";

function CourseEdit(props) {
  const organization = useSelector((state) => state.organization.value);
  const { record } = useEditController(props);
  const transform = (data) => {
    const { teachers } = data;

    let newTaughtCourses = [];
    if (teachers) {
      newTaughtCourses = teachers.reduce((acc, teacherId) => {
        if (!record.teachers.includes(teacherId)) {
          acc.push({
            teacherId,
          });
        }
        return acc;
      }, []);
    }
    return { ...data, taughtCourses: newTaughtCourses };
  };

  return (
    <Edit {...props} transform={transform} mutationMode="optimistic">
      <TabbedForm>
        <FormTab label="information">
          <TextInput source="name" label="name" />
          <TextInput source="number" label="number" />
          <TextInput source="code" label="code" />
          <TextInput source="description" label="description" fullWidth />
          <ImageInput source="icon" label="icon">
            <ImageField source="url" />
          </ImageInput>
          <TextInput source="category" label="category" />
          <SelectInput
            source="status"
            label="status"
            choices={[
              { id: "ARCHIVED", name: "Archived" },
              { id: "ACTIVE", name: "Active" },
            ]}
          />
          {organization.id === 0 && (
            <ReferenceInput source="organizationId" reference="Organization" label="organization" perPage={99999}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          <ReferenceInput source="folder" reference="CourseFolder" label="courseFolder">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Teachers">
          <ReferenceArrayInput source="teachers" reference="User" label="teachers" fullWidth>
            <SelectArrayInput source="name" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export default CourseEdit;
