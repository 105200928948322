import {
  Datagrid,
  DateField,
  List,
  ReferenceInput,
  AutocompleteInput,
  useTranslate,
  TextField,
  useListController,
  Loading,
} from "react-admin";
import { useSelector } from "react-redux";

function CourseList(props) {
  const { loading } = useListController(props);
  const translate = useTranslate();
  const organization = useSelector((state) => state.organization.value);

  const courseFilters =
    organization.id !== 0
      ? []
      : [
          <ReferenceInput source="organizationId" reference="Organization" alwaysOn>
            <AutocompleteInput optionText="name" emptyText={translate("all")} />
          </ReferenceInput>,
        ];

  return (
    <List {...props} filters={courseFilters} filter={organization.id === 0 ? {} : { organizationId: organization.id }}>
      {loading ? (
        <Loading />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" label="id" />
          <TextField source="name" label="name" />
          <TextField source="description" label="description" />
          <TextField source="status" label="status" />
          <DateField source="createdAt" label="createdAt" />
          <DateField source="updatedAt" label="updatedAt" />
        </Datagrid>
      )}
    </List>
  );
}

export default CourseList;
