import React, { useEffect } from "react";
import { InputAdornment, MenuItem, Select, TextField, makeStyles } from "@material-ui/core";
import { useInput, useTranslate } from "ra-core";

const useStyles = makeStyles({
  select: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export default function EmailBox(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const { emailDomains, selectDomainIndex, domainIndex } = props;

  const {
    input: { value, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  useEffect(() => {
    if (emailDomains?.length > 0 && value.includes("@")) {
      const event = { target: { value: value.split("@")[0] } };
      onChange(event);
    }
  }, [value, emailDomains, onChange]);

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <TextField
      required={isRequired}
      value={value}
      label={translate("email")}
      onChange={handleChange}
      error={!!(touched && error)}
      size="small"
      style={{ width: 400 }}
      variant="filled"
      type={emailDomains?.length >= 1 ? "text" : "email"}
      helperText={!!(touched && error) ? error.message : " "}
      InputProps={{
        endAdornment: emailDomains?.length >= 1 && (
          <InputAdornment position="end">
            {emailDomains.length === 1 ? (
              emailDomains[0]
            ) : (
              <Select
                disableUnderline
                autoWidth
                onChange={selectDomainIndex}
                value={domainIndex}
                className={classes.select}
              >
                {emailDomains.map((domain, index) => (
                  <MenuItem key={domain} value={index}>
                    {domain}
                  </MenuItem>
                ))}
              </Select>
            )}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}
