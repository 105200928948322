import { Datagrid, DateField, List, NumberField, TextField } from "react-admin";

function GradeList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <NumberField source="score" />
        <TextField source="passFail" />
        <NumberField source="maxScore" />
        <DateField source="scoredAt" />
      </Datagrid>
    </List>
  );
}

export default GradeList;
