import { cloneElement, Children } from "react";
import { Pagination, useListController, ListContextProvider } from "react-admin";

function MemberList({ children, organizationId }) {
  const listContext = useListController({
    basePath: "/User",
    resource: "User",
    filter: { organization: organizationId },
  });

  return (
    <ListContextProvider value={{ ...listContext }}>
      {children &&
        cloneElement(Children.only(children), {
          ...listContext,
        })}
      <Pagination />
    </ListContextProvider>
  );
}
export default MemberList;
