import { gql, useLazyQuery } from "@apollo/client";
import { Card, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import {
  BooleanField,
  Datagrid,
  Loading,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  useShowController,
  TopToolbar,
  EditButton,
} from "react-admin";
import StudentList from "../../components/class/StudentList";
import BackButton from "../../components/backButton";

const GET_STUDENTS = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      id
      name
      email
      studentNumber
    }
  }
`;

const useStyles = makeStyles({
  list: {
    marginTop: 20,
    backgroundColor: "white",
    padding: 16,
  },
  actions: { justifyContent: "space-between" },
});

function ClassShow(props) {
  const classes = useStyles();
  const { record } = useShowController(props);
  const [getStudents, { loading, data }] = useLazyQuery(GET_STUDENTS);

  const Actions = ({ basePath, data }) => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  useEffect(() => {
    if (record?.students) {
      getStudents({
        variables: {
          where: {
            id: {
              in: record.students,
            },
          },
        },
      });
    }
  }, [record, getStudents]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Show {...props} actions={<Actions />}>
        <SimpleShowLayout>
          <TextField source="name" label="name" />
          <TextField source="year" label="year" />
          <BooleanField source="active" label="active" />
          <ReferenceField source="organization" reference="Organization" label="organization" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
      {data && record && (
        <Card className={classes.list}>
          <StudentList data={data.users} ids={record.students} classId={record.id}>
            <Datagrid>
              <TextField source="name" label="name" />
              <TextField source="email" label="email" />
              <NumberField source="studentNumber" label="studentNumber" />
            </Datagrid>
          </StudentList>
        </Card>
      )}
    </>
  );
}

export default ClassShow;
