import jwt from "jsonwebtoken";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

const handleTokenError = async (error) => {
  console.log("error", error);
  if (error.message.includes("jwt expired")) {
    // TEST: Token expired error will be handled in apollo client
    return Promise.resolve();
  } else {
    console.error("jwt error", error);
    return Promise.reject();
  }
};

const authProvider = {
  checkAuth: () => {
    const idToken = cookies.get("idToken");
    if (idToken) {
      try {
        const payload = jwt.verify(idToken, process.env.REACT_APP_SECRET || "");
        const { permissions, isSuperAdmin } = payload.user;

        localStorage.setItem("permissions", permissions);
        if (!isSuperAdmin) {
          return Promise.reject({
            redirectTo: "/login?error=NO_PERMISSION",
          });
        } else {
          return Promise.resolve();
        }
      } catch (error) {
        return handleTokenError(error);
      }
    } else {
      return Promise.reject();
    }
  },
  logout: () => {
    cookies.remove("idToken");
    cookies.remove("refreshToken");
    localStorage.clear();
    return Promise.resolve();
  },
  getPermissions: () => {
    const permissions = localStorage.getItem("permissions");
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  checkError: (error) => {
    return Promise.resolve(error);
  },
};

export default authProvider;
