import { BooleanField, Datagrid, DateField, List } from "react-admin";

function ProgressList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <BooleanField source="completed" addLabel />
        <DateField source="startedAt" />
        <DateField source="completedAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export default ProgressList;
