import { gql, useMutation } from "@apollo/client";
import { Button, useRefresh } from "react-admin";

const REMOVE_STUDENTS = gql`
  mutation UpdateOneClass($data: ClassUpdateInput!, $where: ClassWhereUniqueInput!) {
    updateOneClass(data: $data, where: $where) {
      id
    }
  }
`;

function BulkRemoveClassButton({ selectedIds, classId, ...others }) {
  const refresh = useRefresh();
  const [removeStudents] = useMutation(REMOVE_STUDENTS, {
    variables: {
      where: {
        id: classId,
      },
      data: {
        students: {
          deleteMany: {
            userId: {
              in: selectedIds,
            },
          },
        },
      },
    },
    onCompleted() {
      refresh();
    },
  });

  const handleClick = () => {
    removeStudents();
  };

  return <Button label="removeFromClass" onClick={handleClick} />;
}

export default BulkRemoveClassButton;
