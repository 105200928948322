import { ApolloProvider } from "@apollo/client";
import { useEffect, useState } from "react";
import { Admin, Loading, Resource } from "react-admin";
import client from "./config/apolloClient";
import authProvider from "./config/authProvider";
import AssignmentList from "./resources/assignment";
import LoginPage from "./pages/login";
import UserList from "./resources/user";
import PermissionList from "./resources/permission";
import RoleList from "./resources/role";
import CourseList from "./resources/course";
import ClassList from "./resources/class";
import CourseFolderList from "./resources/courseFolder";
import PostList from "./resources/post";
import GradeList from "./resources/grade";
import ProgressList from "./resources/progress";
import ProjectList from "./resources/project";
import TutorialList from "./resources/tutorial";
import CourseTutorialList from "./resources/courseTutorial";
import LearningConceptList from "./resources/learningConcept";
import ComponentList from "./resources/component";
import UserShow from "./resources/user/show";
import UserCreate from "./resources/user/create";
import UserEdit from "./resources/user/edit";
import { getDataProvider } from "./config/dataProvider";
import PermissionCreate from "./resources/permission/create";
import PermissionEdit from "./resources/permission/edit";
import RoleEdit from "./resources/role/edit";
import RoleCreate from "./resources/role/create";
import AssignmentShow from "./resources/assignment/show";
import AssignmentEdit from "./resources/assignment/edit";
import CourseShow from "./resources/course/show";
import CourseEdit from "./resources/course/edit";
import ClassCreate from "./resources/class/create";
import ClassShow from "./resources/class/show";
import ClassEdit from "./resources/class/edit";
import OrganizationList from "./resources/organization";
import OrganizationCreate from "./resources/organization/create";
import OrganizationEdit from "./resources/organization/edit";
import OrganizationShow from "./resources/organization/show";
import AppLayout from "./components/Layout";
import i18nProvider from "./config/i18nProvider";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import { createHashHistory } from "history";
import CourseTutorialShow from "./resources/courseTutorial/show";

function App() {
  const [dataProvider, setDataProvider] = useState();
  const history = createHashHistory();

  useEffect(() => {
    getDataProvider().then((dp) => {
      setDataProvider(() => dp);
    });
  }, []);

  if (!dataProvider) {
    return <Loading />;
  }

  return (
    <Provider
      store={configureStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <ApolloProvider client={client}>
        <Admin
          loginPage={LoginPage}
          authProvider={authProvider}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          history={history}
          layout={AppLayout}
        >
          <Resource name="User" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} />
          <Resource name="Permission" list={PermissionList} create={PermissionCreate} edit={PermissionEdit} />
          <Resource name="Role" list={RoleList} edit={RoleEdit} create={RoleCreate} />
          <Resource name="Assignment" list={AssignmentList} show={AssignmentShow} edit={AssignmentEdit} />
          <Resource name="Course" list={CourseList} show={CourseShow} edit={CourseEdit} />
          <Resource name="Class" list={ClassList} create={ClassCreate} show={ClassShow} edit={ClassEdit} />
          <Resource name="CourseFolder" list={CourseFolderList} />
          <Resource name="Post" list={PostList} />
          <Resource name="Grade" list={GradeList} />
          <Resource name="Progress" list={ProgressList} />
          <Resource name="Project" list={ProjectList} />
          <Resource name="Tutorial" list={TutorialList} />
          <Resource name="CourseTutorial" list={CourseTutorialList} show={CourseTutorialShow} />
          <Resource name="LearningConcept" list={LearningConceptList} />
          <Resource name="Component" list={ComponentList} />
          <Resource
            name="Organization"
            list={OrganizationList}
            create={OrganizationCreate}
            show={OrganizationShow}
            edit={OrganizationEdit}
          />
          <Resource name="File" />
          <Resource name="Validator" />
          <Resource name="AssignmentSubmission" />
        </Admin>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
