import { JsonField } from "react-admin-json-view";

function JsonDataField({ record, source }) {
  const newRecord = {
    ...record,
    [source]: JSON.stringify(JSON.parse(record[source].replaceAll("'", '"'))),
  };
  return (
    <JsonField
      source={source}
      jsonString
      record={newRecord}
      reactJsonOptions={{
        displayDataTypes: false,
        name: null,
        enableClipboard: false,
      }}
    />
  );
}

export default JsonDataField;
