import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  ReferenceArrayInput,
  required,
  AutocompleteArrayInput,
  FormTab,
  TabbedForm,
  SimpleFormIterator,
  TextInput,
  useNotify,
  TopToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function OrganizationCreate(props) {
  const classes = useStyles();
  const notify = useNotify();
  
  const Actions = () => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
      </TopToolbar>
    );
  };

  const handleFailure = (error) => {
    notify(`Could not create organization: ${error.message}`);
  };

  return (
    <Create {...props} onFailure={handleFailure} actions={<Actions />}>
      <TabbedForm redirect="list">
        <FormTab label="information">
          <TextInput source="name" validate={required()} label="name" />
          <TextInput source="code" label="code" />
          <DateInput source="subscriptionExpiryDate" label="subscriptionExpiryDate" />
          <BooleanInput source="internal" label="internal" />
          <ArrayInput source="emailDomains" label="emailDomains">
            <SimpleFormIterator>
              <TextInput optionText="domain" source="" validate={required()} label="emailDomains" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="members">
          <ReferenceArrayInput source="members" reference="User" label="members">
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

export default OrganizationCreate;
