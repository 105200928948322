import { Cookies } from "react-cookie";
import { getBackendHost } from "../config/server";

export const getNewToken = async () => {
  const cookies = new Cookies();
  const refreshToken = cookies.get("refreshToken");
  const response = await fetch(getBackendHost(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      operationName: null,
      variables: {},
      query: `mutation { refreshToken( token: "${refreshToken}") { token, refreshToken }}`,
    }),
  });

  return response.json();
};

export default getNewToken;
