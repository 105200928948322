import {
  BooleanField,
  Datagrid,
  DateField,
  FileField,
  ImageField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  RichTextField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import JsonDataField from "../../components/JsonDataField";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function AssignmentShow(props) {
  const classes = useStyles();
  const Actions = ({ basePath, data }) => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };
  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="information">
          <ReferenceField source="author" reference="User" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" label="name" />
          <ImageField source="icon.url" label="icon" />
          <RichTextField source="instructions" />
          <ReferenceField source="course" reference="Course" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <DateField source="deadline" label="deadline" />
          <BooleanField source="passFail" addLabel />
          <NumberField source="maxScore" />
          <TextField source="status" label="status" />
          <DateField source="assignedAt" label="assignedAt" />
          <DateField source="createdAt" label="createdAt" />
          <DateField source="updatedAt" label="updatesAt" />
          <DateField source="archivedAt" />
          <BooleanField source="allowLateSubmissions" addLabel />
        </Tab>
        <Tab label="validator">
          <ReferenceField source="validator" reference="Validator">
            <DateField source="createdAt" label="createdAt" />
            <DateField source="updatedAt" label="updatedAt" />
            <BooleanField source="autoGenerated" addLabel />
            <JsonDataField source="answerProjectNode" />
            <TextField source="code" label="code" />
          </ReferenceField>
        </Tab>
        <Tab label="assignee">
          <ReferenceArrayField source="assignedTo" reference="User">
            <Datagrid>
              <TextField source="name" label="name" />
              <TextField source="email" label="email" />
              <ImageField source="avatar.url" title="avatar.filename" label="Avatar" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="submissions">
          <ReferenceArrayField source="submissions" reference="AssignmentSubmission">
            <Datagrid>
              <ReferenceField source="user" reference="User" link={false} label="users">
                <TextField source="name" label="name" />
              </ReferenceField>
              <JsonDataField source="projectNode" />
              <TextField source="status" label="status" />
              <ReferenceField source="grade" reference="Grade">
                <TextField source="score" />
              </ReferenceField>
              <DateField source="createdAt" label="createdAt" />
              <DateField source="updatedAt" label="updatedAt" />
              <DateField source="submittedAt" />
              <ReferenceField source="attachments" reference="File">
                <FileField source="url" title="filename" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default AssignmentShow;
