import {
  Datagrid,
  List,
  TextField,
  DataProviderContext,
  useTranslate,
  RichTextField,
  AutocompleteInput,
  ReferenceInput,
  useListController,
  Loading,
} from "react-admin";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

function AssignmentList(props) {
  const { loading } = useListController(props);
  const organization = useSelector((state) => state.organization.value);
  const dataProvider = useContext(DataProviderContext);
  const translate = useTranslate();
  const [users, setUsers] = useState();

  useEffect(() => {
    if (organization.id !== 0) {
      dataProvider.getOne("Organization", { id: organization.id }).then(({ data }) => {
        setUsers(data.members);
      });
    }
  }, [dataProvider, organization]);

  const handleChange = (organizationId) => {
    if (organizationId)
      dataProvider.getOne("Organization", { id: organizationId }).then(({ data }) => {
        setUsers(data.members);
      });
    else setUsers();
  };

  const filters =
    organization.id !== 0
      ? []
      : [
          <ReferenceInput
            source="organization"
            label="organization"
            reference="Organization"
            alwaysOn
            perPage={99999}
            onChange={handleChange}
          >
            <AutocompleteInput optionText="name" emptyText={translate("all")} />
          </ReferenceInput>,
        ];

  return (
    <>
      <List {...props} empty={false} filters={filters} filter={{ authorId: users }}>
        {loading ? (
          <Loading />
        ) : (
          <Datagrid rowClick="show">
            <TextField source="id" label="id" />
            <TextField source="name" label="name" />
            <RichTextField source="instructions" label="instructions" stripTags />
            <TextField source="deadline" label="deadline" />
            <TextField source="status" label="status" />
          </Datagrid>
        )}
      </List>
    </>
  );
}

export default AssignmentList;
