import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import chineseMessages from "ra-language-chinese";
import chineseTWMessages from "ra-language-chinese-traditional";
import en from "../translations/en.json";
import zhHans from "../translations/zh-Hans.json";
import zhHant from "../translations/zh-Hant.json";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "en") {
    return { ...englishMessages, ...en };
  }
  if (locale === "zh-Hans") {
    return { ...chineseMessages, ...zhHans };
  }
  if (locale === "zh-Hant") {
    return { ...chineseTWMessages, ...zhHant };
  }
}, "en");

export default i18nProvider;
