import { Datagrid, List, ReferenceArrayField, TextField } from "react-admin";

function RoleList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name" label="name" />
        <TextField source="isAdmin" label="isAdmin" />
        <ReferenceArrayField label="permissions" reference="Permission" source="permissions">
          <Datagrid>
            <TextField source="name" />
          </Datagrid>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
}

export default RoleList;
