import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import { Button, useNotify, useRedirect, useRefresh } from "react-admin";

const useStyles = makeStyles({
  root: {
    color: "red",

    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
});

const DELETE_ONE_USER = gql`
  mutation deleteOneUser($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
    }
  }
`;

const GET_COURSES = gql`
  query courses($where: CourseWhereInput) {
    courses(where: $where) {
      id
    }
  }
`;

const GET_ASSIGNMENTS = gql`
  query assignments($where: AssignmentWhereInput) {
    assignments(where: $where) {
      id
    }
  }
`;

const GET_COURSE_TUTORIALS = gql`
  query courseTutorials($where: CourseTutorialWhereInput) {
    courseTutorials(where: $where) {
      id
    }
  }
`;

const REMOVE_STUDENT_FROM_COURSE = gql`
  mutation removeStudentFromCourse($courseId: Int!, $userId: Int!) {
    removeStudentFromCourse(courseId: $courseId, userId: $userId) {
      id
    }
  }
`;

const UPDATE_ONE_ASSIGNMENT = gql`
  mutation updateOneAssignment($data: AssignmentUpdateInput!, $where: AssignmentWhereUniqueInput!) {
    updateOneAssignment(data: $data, where: $where) {
      id
    }
  }
`;

const UPDATE_ONE_COURSE_TUTORIAL = gql`
  mutation updateOneCourseTutorial($data: CourseTutorialUpdateInput!, $where: CourseTutorialWhereUniqueInput!) {
    updateOneCourseTutorial(data: $data, where: $where) {
      id
    }
  }
`;

function DeleteButton({ record, handleClose, ...other }) {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [getCourses, { loading, data: coursesData }] = useLazyQuery(GET_COURSES, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [getAssignments, { loading: assignmentsLoading, data: assignmentsData }] = useLazyQuery(GET_ASSIGNMENTS, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [getCourseTutorials, { loading: tutorialsLoading, data: tutorialsData }] = useLazyQuery(GET_COURSE_TUTORIALS, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [removeStudentFromCourse, { loading: removeStudentLoading, data: removeStudentData }] = useMutation(
    REMOVE_STUDENT_FROM_COURSE,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [updateOneAssignment, { loading: updateAssignmentLoading, data: updateAssignmentData }] = useMutation(
    UPDATE_ONE_ASSIGNMENT,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [updateOneCourseTutorial, { loading: updateTutorialLoading, data: updateTutorialData }] = useMutation(
    UPDATE_ONE_COURSE_TUTORIAL,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [deleteOneUser] = useMutation(DELETE_ONE_USER, {
    onCompleted() {
      if (
        (coursesData.courses.length === 0 && assignmentsData.assignments.length === 0) ||
        tutorialsData.courseTutorials.length === 0
      ) {
        redirect("/User");
        setTimeout(() => {
          refresh();
        }, 300);
      }
    },
  });

  useEffect(() => {
    if (record.id) {
      getCourses({
        variables: {
          where: {
            students: {
              some: {
                userId: {
                  equals: record.id,
                },
              },
            },
          },
        },
      });

      getAssignments({
        variables: {
          where: {
            assignedAssignments: {
              some: {
                userId: {
                  equals: record.id,
                },
              },
            },
          },
        },
      });

      getCourseTutorials({
        variables: {
          where: {
            assignedTutorials: {
              some: {
                userId: {
                  equals: record.id,
                },
              },
            },
          },
        },
      });
    }
  }, [record, getCourses, getAssignments, getCourseTutorials]);

  const handleClick = () => {
    deleteOneUser({
      variables: {
        where: {
          id: record.id,
        },
      },
    });

    if (coursesData.courses.length > 0) {
      coursesData.courses.forEach((course) => {
        removeStudentFromCourse({
          variables: {
            courseId: course.id,
            userId: record.id,
          },
        });
      });
    }

    if (assignmentsData.assignments.length > 0) {
      assignmentsData.assignments.forEach((assignment) => {
        updateOneAssignment({
          variables: {
            where: {
              id: assignment.id,
            },
            data: {
              assignedAssignments: {
                delete: [
                  {
                    uc_assigned_assignment: { userId: record.id, assignmentId: assignment.id },
                  },
                ],
              },
            },
          },
        });
      });
    }

    if (tutorialsData.courseTutorials.length > 0) {
      tutorialsData.courseTutorials.forEach((tutorial) => {
        updateOneCourseTutorial({
          variables: {
            where: {
              id: tutorial.id,
            },
            data: {
              assignedTutorials: {
                delete: [
                  {
                    uc_assigned_tutorial: { userId: record.id, courseTutorialId: tutorial.id },
                  },
                ],
              },
            },
          },
        });
      });
    }
  };

  if (removeStudentData || updateAssignmentData || updateTutorialData) {
    redirect("/User");
    setTimeout(() => {
      refresh();
    }, 300);
  }

  return (
    <Button
      className={classes.root}
      startIcon={<DeleteIcon />}
      label="delete"
      disabled={
        loading ||
        removeStudentLoading ||
        assignmentsLoading ||
        updateAssignmentLoading ||
        tutorialsLoading ||
        updateTutorialLoading
      }
      onClick={() => {
        handleClose();
        handleClick();
      }}
    />
  );
}

export default DeleteButton;
