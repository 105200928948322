import {
  Datagrid,
  DateField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  BooleanField,
  NumberField,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function CourseShow(props) {
  const classes = useStyles();
  const Actions = ({ basePath, data }) => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="information">
          <TextField source="name" label="name" />
          <TextField source="number" label="number" />
          <TextField source="code" label="code" />
          <TextField source="description" label="description" fullWidth />
          <ImageField source="icon.url" title="icon.filename" label="icon" />
          <TextField source="category" label="category" />
          <TextField source="status" label="status" />
          <ReferenceField source="organization" reference="Organization" link={false} label="organization">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="folder.name" />
        </Tab>
        <Tab label="posts">
          <ReferenceArrayField
            source="posts"
            reference="Post"
            label="post"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid>
              <ReferenceField source="author" reference="User" label="author">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="content" label="content" />
              <DateField source="postDate" label="postDate" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="teachers">
          <ReferenceArrayField
            source="teachers"
            reference="User"
            label="teachers"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid>
              <TextField source="name" label="name" />
              <TextField source="email" label="email" />
              <ImageField source="avatar.url" label="avatar" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="assignee">
          <ReferenceArrayField
            source="students"
            reference="User"
            label="students"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid>
              <TextField source="name" label="name" />
              <TextField source="email" label="email" />
              <TextField source="avatar.url" label="avatar" />
            </Datagrid>
          </ReferenceArrayField>
          <ReferenceArrayField
            source="classes"
            reference="Class"
            label="classes"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid>
              <TextField source="name" label="name" />
              <TextField source="year" label="year" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="assignment">
          <ReferenceArrayField
            source="assignments"
            reference="Assignment"
            label="assignment"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid rowClick="show">
              <TextField source="id" label="id" />
              <TextField source="name" label="name" />
              <ReferenceField source="author" reference="User" link={false} label="author">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="deadline" label="deadline" />
              <BooleanField source="passFail" label="passFail" />
              <NumberField source="maxScore" label="maxScore" />
              <TextField source="status" label="status" />
              <BooleanField source="allowLateSubmissions" label="allowLateSubmissions" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="courseTutorial">
          <ReferenceArrayField
            source="tutorials"
            reference="CourseTutorial"
            label="courseTutorial"
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}
          >
            <Datagrid rowClick="show">
              <TextField source="id" label="id" />
              <ReferenceField source="id" reference="Tutorial" label="name" link={false}>
                <NumberField source="name" />
              </ReferenceField>
              <DateField source="archivedAt" label="archivedAt" />
              <DateField source="assignedAt" label="assignedAt" />
              <DateField source="createdAt" label="createdAt" />
              <DateField source="deadline" label="deadline" />
              <NumberField source="maxScore" label="maxScore" />
              <BooleanField source="passFail" label="passFail" />
              <TextField source="status" label="status" />
              <BooleanField source="allowLateSubmissions" label="allowLateSubmissions" />
              <DateField source="updatedAt" label="updatedAt" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default CourseShow;
