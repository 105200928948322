import { Datagrid, List, TextField } from "react-admin";

function LearningConceptList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="concept" />
      </Datagrid>
    </List>
  );
}

export default LearningConceptList;
