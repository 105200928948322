import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
function CourseTutorialShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="id" />
        <ReferenceField source="id" reference="Tutorial" label="name" link={false}>
          <NumberField source="name" />
        </ReferenceField>
        <ReferenceField source="course" reference="Course" label="courseId">
          <TextField source="courseId" />
        </ReferenceField>
        <DateField source="archivedAt" label="archivedAt" />
        <DateField source="assignedAt" label="assignedAt" />
        <DateField source="createdAt" label="createdAt" />
        <DateField source="deadline" label="deadline" />
        <NumberField source="maxScore" label="maxScore" />
        <BooleanField source="passFail" label="passFail" />
        <TextField source="status" label="status" />
        <BooleanField source="allowLateSubmissions" label="allowLateSubmissions" />
        <DateField source="updatedAt" label="updatedAt" />
        <ReferenceArrayField source="assignedTo" reference="User" label="assignedTo">
          <Datagrid rowClick="show">
            <TextField source="id" label="id" />
            <TextField source="name" label="name" />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
}
export default CourseTutorialShow;
