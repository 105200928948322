import {
  Menu,
  MenuItemLink,
  useTranslate,
  LoadingIndicator,
  UserMenu,
  DataProviderContext,
  useLogout,
} from "react-admin";
import { useState, useEffect, useContext } from "react";
import {
  Typography,
  IconButton,
  Drawer,
  AppBar,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import LanguageIcon from "@material-ui/icons/Language";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { changeOrganization, toggleMenu } from "../organizationSlice";
import LanguageMenu from "./LanguageMenu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  outerMenu: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },
  drawer: {
    width: 220,
  },
  title: {
    width: "10em",
  },
  organization: {
    flex: 1,
    textAlign: "center",
  },
  userMenu: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "10em",
  },
  dropDown: {
    width: 200,
    paddingBottom: 16,
    alignSelf: "center",
  },
  menuItem: { color: theme.palette.text.secondary },
  icon: { marginRight: "0.7em" },
}));

const AdminAppBar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState();
  const [openLanguage, setOpenLanguage] = useState(false);
  const translate = useTranslate();
  const dataProvider = useContext(DataProviderContext);
  const [organizations, setOrganizations] = useState();
  const organization = useSelector((state) => state.organization.value);
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = useLogout();

  useEffect(() => {
    if (open)
      dataProvider
        .getList("Organization", {
          pagination: {},
        })
        .then(({ data }) => {
          setOrganizations(data);
        });
  }, [open]);

  const handleClose = () => {
    dispatch(toggleMenu(true));
    setOpen(false);
    history.push("/");
  };

  const handleClick = () => {
    setOpen(false);
    dispatch(toggleMenu(false));
  };

  const handleChange = (event) => {
    const selectedOrg = organizations[event.target.value - 1] ?? { id: 0, emailDomains: [], name: "" };
    dispatch(
      changeOrganization({ id: selectedOrg.id, emailDomains: selectedOrg.emailDomains, name: selectedOrg.name }),
    );
  };

  const handleOpenLanguage = () => {
    setOpenLanguage(true);
  };

  const handleCloseLanguage = () => {
    setOpenLanguage(false);
  };

  const handleLogout = () => logout();

  const MenuList = () => (
    <>
      <MenuItem className={classes.menuItem} tabIndex={0} onClick={handleOpenLanguage}>
        <ListItemIcon>
          <LanguageIcon titleAccess="Locale" className={classes.icon} /> {translate("locale")}
        </ListItemIcon>
      </MenuItem>
      <MenuItem className={classes.menuItem} tabIndex={0} onClick={handleLogout}>
        <ListItemIcon>
          <PowerSettingsNewIcon titleAccess="Logout" className={classes.icon} />
          {translate("logout")}
        </ListItemIcon>
      </MenuItem>
    </>
  );

  return (
    <>
      <AppBar {...props} className={classes.appBar} color="secondary">
        <IconButton className={classes.outerMenu} color="inherit" onClick={() => setOpen(!open)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" id="react-admin-title" className={classes.title} />
        <Typography variant="h6" color="inherit" className={classes.organization}>
          {organization.name}
        </Typography>
        <div className={classes.userMenu}>
          <UserMenu>
            <MenuList />
          </UserMenu>
          <LoadingIndicator />
        </div>

        <Drawer open={open} onClose={() => setOpen(false)}>
          <div className={classes.drawer}>
            <Menu {...props} className={classes.drawer}>
              <FormControl fullWidth className={classes.dropDown} variant="filled">
                <InputLabel>Organization</InputLabel>
                <Select
                  value={organization.id}
                  label="Organization"
                  onChange={handleChange}
                  onClose={handleClose}
                  defaultValue={{ label: "All", value: 0 }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {organizations &&
                    organizations.map((organization, index) => (
                      <MenuItem key={organization.id} value={index + 1}>
                        {organization.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <MenuItemLink
                onClick={handleClick}
                className={classes.drawer}
                to={"/Organization"}
                primaryText={translate("organization")}
              />
              <MenuItemLink
                onClick={handleClick}
                style={{ width: 240 }}
                to={`/Permission`}
                primaryText={translate("permissions")}
              />
              <MenuItemLink
                onClick={handleClick}
                style={{ width: 240 }}
                to={`/Role`}
                primaryText={translate("roles")}
              />
              <MenuItemLink
                onClick={handleClick}
                style={{ width: 240 }}
                to={`/Tutorial`}
                primaryText={translate("tutorial")}
              />
            </Menu>
          </div>
        </Drawer>
      </AppBar>
      <LanguageMenu open={openLanguage} handleClose={handleCloseLanguage} />
    </>
  );
};

export default AdminAppBar;
