import { Edit, SelectInput, SimpleForm, TopToolbar } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function PermissionEdit(props) {
  const classes = useStyles();
  const Actions = () => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
      </TopToolbar>
    );
  };
  return (
    <Edit {...props} mutationMode="optimistic" actions={<Actions />}>
      <SimpleForm>
        <SelectInput
          source="action"
          choices={[
            { id: "READ", name: "READ" },
            { id: "CREATE", name: "CREATE" },
            { id: "UPDATE", name: "UPDATE" },
            { id: "DELETE", name: "DELETE" },
          ]}
        />
        <SelectInput
          source="access"
          choices={[
            { id: "ANY", name: "ANY" },
            { id: "ORG", name: "ORG" },
            { id: "OWN", name: "OWN" },
            { id: "PARTIAL", name: "PARTIAL" },
          ]}
        />
        <SelectInput
          source="resource"
          choices={[
            { id: "AC", name: "AC" },
            { id: "TC", name: "TC" },
            { id: "SC", name: "SC" },
            { id: "TM", name: "TM" },
            { id: "USER", name: "USER" },
            { id: "ORGANIZATION", name: "ORGANIZATION" },
            { id: "ROLE", name: "ROLE" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}

export default PermissionEdit;
