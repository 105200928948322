import { Loading, useAuthState } from "react-admin";
import { useLocation } from "react-router-dom";
import { getAuthHost } from "../../config/server";

function Login() {
  const { loading } = useAuthState();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const redirect_uri = `${getAuthHost()}/login?redirect_uri=${encodeURIComponent(
    `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}`,
  )}`;
  if (loading) {
    return <Loading />;
  }
  if (urlParams.get("error") === "NO_PERMISSION") {
    window.location.href = `${redirect_uri}&error=no_permission`;
  } else {
    window.location.href = `${redirect_uri}`;
  }

  return null;
}

export default Login;
