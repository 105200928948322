import Person from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Menu, MenuItemLink, useTranslate } from "react-admin";

const AdminMenu = (props) => {
  const translate = useTranslate();
  return (
    <Menu {...props} style={{ width: 240 }}>
      <MenuItemLink style={{ width: 240 }} to={`/User`} primaryText={translate("users")} leftIcon={<Person />} />
      <MenuItemLink style={{ width: 240 }} to={"/Class"} primaryText={translate("classes")} leftIcon={<GroupIcon />} />
      <MenuItemLink
        style={{ width: 240 }}
        to={"/Course"}
        primaryText={translate("courses")}
        leftIcon={<MenuBookIcon />}
      />
      <MenuItemLink
        style={{ width: 240 }}
        to={"/Assignment"}
        primaryText={translate("assignments")}
        leftIcon={<AssignmentIcon />}
      />
      <MenuItemLink
        style={{ width: 240 }}
        to={"/CourseTutorial"}
        primaryText={translate("courseTutorial")}
        leftIcon={<AssignmentIcon />}
      />
    </Menu>
  );
};

export default AdminMenu;
