import { makeStyles } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import {
  BooleanInput,
  Edit,
  /*   ImageField,
  ImageInput, */
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  useEditController,
  email,
  regex,
  Toolbar,
  SaveButton,
  useTranslate,
} from "react-admin";
import { useSelector } from "react-redux";
import DeleteButton from "../../components/user/DeleteButton";
import EmailInput from "../../components/user/EmailInput";

const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
});

function UserEdit(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  const { record } = useEditController(props);
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [domainIndex, setDomainIndex] = useState(null);
  const emailPrefixValidation = regex(/^[a-zA-Z0-9][a-zA-Z0-9-_.]*[a-zA-Z0-9]$|^[a-zA-z0-9]$/, "Invalid email format");
  const initDomain = "@" + record?.email.split("@")[1];
  const domainRef = useRef();
  const globalOrganization = useSelector((state) => state.organization.value);

  useEffect(() => {
    if (globalOrganization.id !== 0) {
      setSelectedOrg(globalOrganization);
      setDomainIndex(globalOrganization.emailDomains.indexOf(initDomain));
    }
  }, [globalOrganization, initDomain]);

  const transform = (data) => {
    const { newPassword } = data;
    const roles = data.roles?.map((roleId) => ({
      id: roleId,
    }));
    const newData = { ...data, email: data.email + domainRef.current };
    return { ...newData, roles, ...(newPassword ? { password: newPassword } : {}) };
  };

  const selectDomainIndex = (event) => {
    const newDomainIndex = event.target.value;
    setDomainIndex(newDomainIndex);
  };

  const getEmailDomains = () => {
    if (selectedOrg?.emailDomains.length > 0) {
      if (initDomain === "gmail.com") {
        domainRef.current = ["@gmail.com", ...selectedOrg.emailDomains][domainIndex];
        return ["@gmail.com", ...selectedOrg.emailDomains];
      } else {
        domainRef.current = selectedOrg.emailDomains[domainIndex];
        return selectedOrg.emailDomains;
      }
    } else {
      domainRef.current = "";
      return [];
    }
  };

  const UserToolbar = (toolbarProps) => (
    <Toolbar {...toolbarProps} className={classes.toolbar}>
      <SaveButton redirect="show" submitOnEnter={true} />
      <DeleteButton {...toolbarProps} />
    </Toolbar>
  );

  const MySelectInput = (inputProps) => {
    const { choices, input } = inputProps;
    const curOrganization = choices.filter((choice) => choice.id === input.value)[0];
    // When the page rendered at first time we need to get the selected domain index.
    if (domainIndex === null) {
      if (curOrganization?.emailDomains?.length > 0) {
        if (initDomain === "gmail.com") {
          setDomainIndex(0);
        } else {
          const curDomainIndex = curOrganization.emailDomains.indexOf(initDomain);
          setDomainIndex(curDomainIndex);
        }
      } else {
        // If the initial data doesn't have selected organization we need to set the domain index to 0
        setDomainIndex(0);
      }
    } else {
      // When select the different organization we will reset the selected domain index
      if (selectedOrg?.id !== curOrganization?.id) {
        setDomainIndex(0);
      }
    }
    setSelectedOrg(curOrganization);
    return <SelectInput {...inputProps} optionText="name" />;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm initialValues={{ ...record, newPassword: "" }} toolbar={<UserToolbar />}>
        {globalOrganization.id === 0 && (
          <ReferenceInput
            reference="Organization"
            source="organization"
            label="Organization"
            allowEmpty
            perPage={99999}
          >
            <MySelectInput />
          </ReferenceInput>
        )}
        <TextInput source="name" validate={required()} />
        {!selectedOrg || selectedOrg.emailDomains.length === 0 ? (
          <EmailInput
            key="full-email"
            source="email"
            label="Email"
            emailDomains={getEmailDomains()}
            domainIndex={domainIndex}
            selectDomainIndex={selectDomainIndex}
            validate={[
              email(translate("invalidEmailFormatMessage")),
              required({ message: translate("requiredMessage") }),
            ]}
            {...rest}
          />
        ) : (
          <EmailInput
            key="part-email"
            source="email"
            label="Email"
            emailDomains={getEmailDomains()}
            domainIndex={domainIndex}
            selectDomainIndex={selectDomainIndex}
            validate={[emailPrefixValidation, required({ message: translate("requiredMessage") })]}
            {...rest}
          />
        )}

        <TextInput
          source="newPassword"
          label="password"
          type="password"
          validate={minLength(6, "Password needs to be at least 6 characters long")}
        />
        {/* <ImageInput source="avatar">
          <ImageField source="url" />
        </ImageInput> */}
        <ReferenceArrayInput source="roles" reference="Role" label="roles">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="isSuperAdmin" label="isSuperAdmin" />
        <BooleanInput source="verified" label="verified" />
        <SelectInput
          source="locale"
          label="locale"
          choices={[
            { id: "EN", name: "EN" },
            { id: "ZH_HANS", name: "ZH_HANS" },
            { id: "ZH_HANT", name: "ZH_HANT" },
          ]}
        />
        <NumberInput source="emailFrequency" label="emailFrequency" />
        <NumberInput source="type" label="type" />
        <TextInput source="link" label="link" />
        <TextInput source="backpackId" label="backpackId" />
        <BooleanInput source="readOnly" label="readOnly" />
      </SimpleForm>
    </Edit>
  );
}

export default UserEdit;
