import { Layout, Sidebar } from "react-admin";
import { useSelector } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import AdminMenu from "./AdminMenu";

function AppLayout(props) {
  const isShowMenu = useSelector((state) => state.organization.isShowMenu);

  const FixedSidebar = (props) => (
    <Sidebar style={{ width: isShowMenu ? 240 : 0, paddingLeft: isShowMenu ? 0 : 18 }} {...props} />
  );

  return <Layout {...props} menu={AdminMenu} appBar={AdminAppBar} sidebar={FixedSidebar} />;
}

export default AppLayout;
