import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: { margin: 4 },
});

export default function ScalarArrayChipField({ record }) {
  const classes = useStyles();
  return <Chip className={classes.root} label={record} />;
}

ScalarArrayChipField.defaultProps = {
  addLabel: true,
};
