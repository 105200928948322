import { useEffect, useRef, useState } from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  ReferenceField,
  useListController,
  Loading,
  useTranslate,
  useGetList,
} from "react-admin";
import { useSelector } from "react-redux";

function CourseTutorialList(props) {
  const { loading } = useListController(props);
  const organization = useSelector((state) => state.organization.value);
  const translate = useTranslate();
  const { data: coursesData } = useGetList("Course");
  const [curOrganizationId, setCurOrganizationId] = useState(organization.id);
  const courseDataRef = useRef([]);

  useEffect(() => {
    courseDataRef.current = Object.values(coursesData)
      .filter((course) => course.organization === curOrganizationId)
      .map((course) => course.id);
  }, [coursesData, curOrganizationId]);

  const handleChange = (id) => {
    setCurOrganizationId(id);
  };

  const courseFilters =
    organization.id !== 0
      ? []
      : [
          <ReferenceInput
            source="organizationId"
            label="organization"
            reference="Organization"
            alwaysOn
            onChange={handleChange}
          >
            <AutocompleteInput optionText="name" emptyText={translate("all")} />
          </ReferenceInput>,
        ];

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <List
          {...props}
          filters={courseFilters}
          filter={
            curOrganizationId
              ? {
                  courseId: courseDataRef.current.length > 0 ? courseDataRef.current : 0,
                }
              : null
          }
        >
          <Datagrid rowClick="show">
            <TextField source="id" label="id" />
            <ReferenceField source="id" reference="Tutorial" label="name" link={false}>
              <NumberField source="name" />
            </ReferenceField>
            <NumberField source="maxScore" label="maxScore" />
            <TextField source="status" label="status" />
            <DateField source="deadline" label="deadline" />
          </Datagrid>
        </List>
      )}
    </>
  );
}

export default CourseTutorialList;
