import {
  Datagrid,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  useListController,
  TopToolbar,
  CreateButton,
  ExportButton,
  useTranslate,
  AutocompleteInput,
  Loading,
} from "react-admin";
import React, { useState } from "react";
import { ImportButton } from "react-admin-import-csv";
import BulkAddClassButton from "../../components/user/BulkAddClassButton";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import jsonExport from "jsonexport/dist";
import { downloadCSV, useNotify, useRefresh } from "ra-core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useSelector } from "react-redux";
import DeleteButton from "../../components/user/DeleteButton";

const CREATE_MANY_USER = gql`
  mutation CreateManyUser($data: [UserCreateInput!]!) {
    createManyUser(data: $data) {
      createdUsers {
        id
      }
      duplicatedUsers {
        id
        name
        email
      }
    }
  }
`;

function UserList(props) {
  const translate = useTranslate();
  const { filterValues, setFilters, loading } = useListController(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const [field, setField] = useState("name");
  const [isLoading, setIsLoading] = useState(false);
  const organization = useSelector((state) => state.organization.value);
  const [createManyUser] = useMutation(CREATE_MANY_USER, {
    onCompleted(data) {
      if (data) {
        const { duplicatedUsers } = data.createManyUser;
        refresh();
        if (duplicatedUsers?.length > 0) {
          const headers = ["id", "name", "email"];
          jsonExport(duplicatedUsers, { headers }, (err, csv) => {
            console.error("Export duplicated users Error", err);
            downloadCSV(csv, `Duplicated_Users_${new Date().toISOString()}`);
          });
        }
      }
    },
    onError(error) {
      notify(`ERROR_IMPORT_MEMBERS: ${error}`);
    },
  });

  const handleChange = (event) => {
    setField(event.target.value);
    setFilters({ name: "", email: "" });
  };

  const handleBulkDelete = (isLoading) => {
    setIsLoading(isLoading);
  };
  const userFilters = [
    ...(organization.id !== 0
      ? []
      : [
          <ReferenceInput source="organization" label="organization" reference="Organization" alwaysOn perPage={99999}>
            <AutocompleteInput optionText="name" emptyText={translate("all")} />
          </ReferenceInput>,
        ]),
    <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 8 }} alwaysOn>
      <FormControl variant="filled" style={{ width: 100, margin: "dense" }} size="small">
        <InputLabel>{translate("field")}</InputLabel>
        <Select value={field} onChange={handleChange}>
          <MenuItem value={"name"}>{translate("name")}</MenuItem>
          <MenuItem value={"email"}>{translate("email")}</MenuItem>
        </Select>
      </FormControl>
      <TextInput style={{ margin: 0, height: 48 }} label="search" source={field} />
    </div>,
  ];

  const UserBulkActionButtons = (bulkActionProps) => (
    <>
      <BulkAddClassButton {...bulkActionProps} organizationId={filterValues?.organization} />
      <DeleteButton {...bulkActionProps} isBulk handleBulkDelete={handleBulkDelete} />
    </>
  );

  const UserActions = (actionProps) => {
    const preCommitCallback = (csvRows) => {
      const organizationId = filterValues?.organization;
      csvRows.forEach((row) => {
        if (row.password.length > 0 && row.password.length < 6) {
          notify("Password needs to be at least 6 characters long", { type: "warning" });
          throw new Error("Password needs to be at least 6 characters long");
        }
      });

      let data = csvRows
        .filter(({ name, email, password }) => name && email && password)
        .map((csvRow) => ({ name: csvRow.name, email: csvRow.email, password: csvRow.password }));
      if (organizationId) {
        data = data.map((item) => ({ ...item, organization: { connect: { id: organizationId } } }));
      }
      createManyUser({ variables: { data } });
    };

    return (
      <TopToolbar>
        <CreateButton />
        <ImportButton
          {...actionProps}
          transformRows={preCommitCallback}
          parseConfig={{
            encoding: "utf8",
            header: true,
            transformHeader: (header) => header.toLowerCase().trim(),
          }}
          label="import"
        />
        <ExportButton />
      </TopToolbar>
    );
  };
  return (
    <List
      {...props}
      filters={userFilters}
      bulkActionButtons={<UserBulkActionButtons />}
      actions={<UserActions />}
      filter={organization.id === 0 ? {} : { organizationId: organization.id }}
    >
      {isLoading || loading ? (
        <Loading />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" label="id" />
          <TextField source="name" label="name" />
          <TextField source="email" label="email" />
          <TextField source="locale" label="locale" />
        </Datagrid>
      )}
    </List>
  );
}

export default UserList;
