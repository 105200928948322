import {
  DateField,
  SingleFieldList,
  BooleanField,
  Datagrid,
  List,
  ArrayField,
  TextField,
  TextInput,
} from "react-admin";
import ScalarArrayChipField from "../../components/scalarChip";

function OrganizationList(props) {
  const searchFilter = [<TextInput label="search" source="name" alwaysOn />];
  return (
    <List {...props} filters={searchFilter}>
      <Datagrid rowClick="show">
        <TextField source="id" label="id" />
        <TextField source="name" label="name" />
        <TextField source="code" label="code" />
        <BooleanField source="active" label="active" />
        <DateField source="subscriptionExpiryDate" label="subscriptionExpiryDate" />
        <ArrayField source="emailDomains" label="emailDomains">
          <SingleFieldList linkType={false}>
            <ScalarArrayChipField />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
}

export default OrganizationList;
