import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Children, cloneElement, useState } from "react";
import {
  BulkActionsToolbar,
  ListContextProvider,
  ListToolbar,
  Pagination,
  TextInput,
  useListController,
  useTranslate,
} from "react-admin";
import BulkRemoveClassButton from "../user/BulkRemoveClassButton";

function StudentList({ ids, classId, children }) {
  const translate = useTranslate();
  const [field, setField] = useState("name");
  const listContext = useListController({
    basePath: "/User",
    resource: "User",
    filter: { id: ids },
  });

  const handleChange = (event) => {
    setField(event.target.value);
    listContext.setFilters({ name: "", email: "" });
  };

  const BulkActionButtons = (bulkActionProps) => <BulkRemoveClassButton {...bulkActionProps} classId={classId} />;
  const filters = [
    <div alwaysOn>
      <FormControl variant="filled" style={{ width: 100, margin: "8px 0px" }} size="small">
        <InputLabel>{translate("field")}</InputLabel>
        <Select value={field} onChange={handleChange}>
          <MenuItem value={"name"}>{translate("name")}</MenuItem>
          <MenuItem value={"email"}>{translate("email")}</MenuItem>
        </Select>
      </FormControl>
      <TextInput label="search" variant="filled" size="small" source={field} />
    </div>,
  ];

  return (
    <ListContextProvider value={{ ...listContext }}>
      <ListToolbar filters={filters} {...listContext} />
      <BulkActionsToolbar>
        <BulkActionButtons />
      </BulkActionsToolbar>
      {children &&
        cloneElement(Children.only(children), {
          ...listContext,
          hasBulkActions: true,
        })}
      <Pagination />
    </ListContextProvider>
  );
}

export default StudentList;
