import {
  BooleanInput,
  Edit,
  DateInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  FormTab,
  TabbedForm,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  useNotify,
} from "react-admin";

function OrganizationEdit(props) {
  const notify = useNotify();

  const handleFailure = (error) => {
    notify(`Could not edit organization: ${error.message}`);
  };

  return (
    <Edit {...props} onFailure={handleFailure}>
      <TabbedForm redirect="show">
        <FormTab label="information">
          <TextInput source="name" validate={required()} label="name" />
          <TextInput source="code" label="code" />
          <DateInput source="subscriptionExpiryDate" label="subscriptionExpiryDate" />
          <BooleanInput source="internal" label="internal" />
          <ArrayInput source="emailDomains" label="emailDomains">
            <SimpleFormIterator>
              <TextInput optionText="Email Domain" source="" validate={required()} label="emailDomains" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="members">
          <ArrayInput source="members" label="members">
            <SimpleFormIterator>
              <ReferenceInput label="name" reference="User">
                <AutocompleteInput source="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export default OrganizationEdit;
