import { gql, useQuery } from "@apollo/client";
import {
  BooleanInput,
  Edit,
  LoadingIndicator,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";

const GET_USERS = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      id
      name
    }
  }
`;

function ClassEdit(props) {
  const organization = useSelector((state) => state.organization.value);
  const transform = (data) => {
    const { organization } = data;

    if (organization) {
      data.organizationId = organization;
    }

    return { ...data };
  };

  const StudentsInput = (inputProps) => {
    const { values } = useFormState();
    const { loading, data } = useQuery(GET_USERS, {
      variables: {
        where: { organizationId: { equals: values.organization } },
      },
    });
    if (loading) {
      return <LoadingIndicator />;
    }
    const studentChoices = data.users.map((user) => ({
      id: user.id,
      name: user.name,
    }));

    return (
      <ReferenceArrayInput {...inputProps} source="students" reference="User" label="students">
        <SelectArrayInput optionText="name" choices={studentChoices} />
      </ReferenceArrayInput>
    );
  };

  return (
    <Edit {...props} transform={transform} mutationMode="optimistic">
      <SimpleForm>
        <TextInput source="name" validate={required()} label="name" />
        <TextInput source="year" validate={required()} abel="year" />
        <BooleanInput source="active" validate={required()} label="active" />
        {organization.id === 0 && (
          <ReferenceInput source="organization" reference="Organization" label="organization">
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <StudentsInput fullWidth />
      </SimpleForm>
    </Edit>
  );
}

export default ClassEdit;
