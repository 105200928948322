import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import { Button, useNotify, useRefresh, useUnselectAll } from "react-admin";

const useStyles = makeStyles({
  root: {
    color: "red",

    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
});

const DELETE_MANY_USER = gql`
  mutation deleteManyUser($where: UserWhereInput) {
    deleteManyUser(where: $where) {
      count
    }
  }
`;

const GET_COURSES = gql`
  query courses($where: CourseWhereInput) {
    courses(where: $where) {
      id
      students {
        id
      }
    }
  }
`;

const GET_ASSIGNMENTS = gql`
  query assignments($where: AssignmentWhereInput) {
    assignments(where: $where) {
      id
      assignedTo {
        id
      }
    }
  }
`;

const GET_COURSE_TUTORIALS = gql`
  query courseTutorials($where: CourseTutorialWhereInput) {
    courseTutorials(where: $where) {
      id
      assignedTo {
        id
      }
    }
  }
`;

const REMOVE_STUDENTS_FROM_COURSE = gql`
  mutation removeStudentsFromCourse($courseId: Int!, $userIds: [Int!]!) {
    removeStudentsFromCourse(courseId: $courseId, userIds: $userIds) {
      id
    }
  }
`;

const UPDATE_ONE_ASSIGNMENT = gql`
  mutation updateOneAssignment($data: AssignmentUpdateInput!, $where: AssignmentWhereUniqueInput!) {
    updateOneAssignment(data: $data, where: $where) {
      id
    }
  }
`;

const UPDATE_ONE_COURSE_TUTORIAL = gql`
  mutation updateOneCourseTutorial($data: CourseTutorialUpdateInput!, $where: CourseTutorialWhereUniqueInput!) {
    updateOneCourseTutorial(data: $data, where: $where) {
      id
    }
  }
`;

function BulkDeleteButton({ selectedIds, handleClose, onBulkDelete, ...other }) {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const unSelectAll = useUnselectAll();
  const [getCourses, { loading, data: coursesData }] = useLazyQuery(GET_COURSES, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [getAssignments, { loading: assignmentsLoading, data: assignmentsData }] = useLazyQuery(GET_ASSIGNMENTS, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [getCourseTutorials, { loading: tutorialsLoading, data: tutorialsData }] = useLazyQuery(GET_COURSE_TUTORIALS, {
    fetchPolicy: "cache-and-network",
    onError(error) {
      notify(`Delete users error: ${error}`, "error");
    },
  });
  const [removeStudentsFromCourse, { loading: removeStudentsLoading, data: removeStudentsData }] = useMutation(
    REMOVE_STUDENTS_FROM_COURSE,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [updateOneAssignment, { loading: updateAssignmentLoading, data: updateAssignmentData }] = useMutation(
    UPDATE_ONE_ASSIGNMENT,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [updateOneCourseTutorial, { loading: updateTutorialLoading, data: updateTutorialData }] = useMutation(
    UPDATE_ONE_COURSE_TUTORIAL,
    {
      onError(error) {
        notify(`Delete users error: ${error}`, "error");
      },
    },
  );
  const [deleteManyUser] = useMutation(DELETE_MANY_USER, {
    onCompleted() {
      unSelectAll("User");
      onBulkDelete(false);
      refresh();
    },
  });

  useEffect(() => {
    if (selectedIds.length > 0) {
      getCourses({
        variables: {
          where: {
            students: {
              some: {
                userId: {
                  in: selectedIds,
                },
              },
            },
          },
        },
      });

      getAssignments({
        variables: {
          where: {
            assignedAssignments: {
              some: {
                userId: {
                  in: selectedIds,
                },
              },
            },
          },
        },
      });

      getCourseTutorials({
        variables: {
          where: {
            assignedTutorials: {
              some: {
                userId: {
                  in: selectedIds,
                },
              },
            },
          },
        },
      });
    }
  }, [selectedIds, getCourses, getAssignments, getCourseTutorials]);

  const handleClick = () => {
    deleteManyUser({
      variables: {
        where: {
          id: {
            in: selectedIds,
          },
        },
      },
    });

    if (coursesData.courses.length > 0) {
      onBulkDelete(true);
      coursesData.courses.forEach((course) => {
        removeStudentsFromCourse({
          variables: {
            courseId: course.id,
            userIds: course.students.reduce((userList, user) => {
              if (selectedIds.includes(user.id)) userList.push(user.id);
              return userList;
            }, []),
          },
        });
      });
    }

    if (assignmentsData.assignments.length > 0) {
      onBulkDelete(true);
      assignmentsData.assignments.forEach((assignment) => {
        updateOneAssignment({
          variables: {
            where: {
              id: assignment.id,
            },
            data: {
              assignedAssignments: {
                delete: selectedIds.reduce((list, userId) => {
                  if (assignment.assignedTo.some((user) => user.id === userId))
                    list.push({ uc_assigned_assignment: { userId, assignmentId: assignment.id } });
                  return list;
                }, []),
              },
            },
          },
        });
      });
    }

    if (tutorialsData.courseTutorials.length > 0) {
      onBulkDelete(true);
      tutorialsData.courseTutorials.forEach((tutorial) => {
        updateOneCourseTutorial({
          variables: {
            where: {
              id: tutorial.id,
            },
            data: {
              assignedTutorials: {
                delete: selectedIds.reduce((list, userId) => {
                  if (tutorial.assignedTo.some((user) => user.id === userId))
                    list.push({ uc_assigned_tutorial: { userId, courseTutorialId: tutorial.id } });
                  return list;
                }, []),
              },
            },
          },
        });
      });
    }
  };

  if (removeStudentsData || updateAssignmentData || updateTutorialData) {
    unSelectAll("User");
    onBulkDelete(false);
    refresh();
  }

  return (
    <Button
      className={classes.root}
      startIcon={<DeleteIcon />}
      label="delete"
      disabled={
        loading ||
        removeStudentsLoading ||
        assignmentsLoading ||
        updateAssignmentLoading ||
        tutorialsLoading ||
        updateTutorialLoading
      }
      onClick={() => {
        handleClose();
        handleClick();
      }}
    />
  );
}

export default BulkDeleteButton;
