import RichTextInput from "ra-input-rich-text";
import {
  Edit,
  FormTab,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  BooleanInput,
  NumberInput,
} from "react-admin";
import { useSelector } from "react-redux";

function AssignmentEdit(props) {
  const organization = useSelector((state) => state.organization.value);

  return (
    <Edit {...props} mutationMode="optimistic">
      <TabbedForm>
        <FormTab label="information">
          <ReferenceInput source="authorId" reference="User">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" label="name" />
          <ImageInput source="icon">
            <ImageField source="url" />
          </ImageInput>
          <RichTextInput
            source="instructions"
            toolbar={[
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ font: [] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],

              ["clean"],
            ]}
          />
          <ReferenceInput
            source="courseId"
            reference="Course"
            filterToQuery={() => (organization.id !== 0 ? { organizationId: organization.id } : {})}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <DateInput source="deadline" label="deadline" />
          <BooleanInput source="passFail" />
          <NumberInput source="maxScore" />
          <SelectInput
            label="status"
            source="status"
            choices={[
              { id: "ARCHIVED", name: "Archived" },
              { id: "ASSIGNED", name: "Assigned" },
              { id: "DRAFT", name: "Draft" },
              { id: "CLOSED", name: "Closed" },
            ]}
          />
          <BooleanInput source="allowLateSubmissions" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export default AssignmentEdit;
