import { createSlice } from "@reduxjs/toolkit";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    value: { id: 0, emailDomains: [], name: "" },
    isShowMenu: true,
  },
  reducers: {
    changeOrganization: (state, action) => {
      state.value = action.payload;
    },
    toggleMenu: (state, action) => {
      state.isShowMenu = action.payload;
    },
  },
});

export const { changeOrganization, toggleMenu } = organizationSlice.actions;

export default organizationSlice.reducer;
