import {
  BooleanField,
  Datagrid,
  List,
  Loading,
  TextField,
  TextInput,
  useListController,
  useTranslate,
} from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useEffect, useState } from "react";

function TutorialList(props) {
  const [field, setField] = useState("name");
  const translate = useTranslate();
  const { filterValues, setFilters, loading } = useListController(props);

  useEffect(() => {
    setFilters({ name: "", status: "" });
  }, []);

  const handleChange = (event) => {
    setField(event.target.value);
    setFilters({ name: "", status: "" });
  };

  const handleStatusChange = (event) => {
    // Set filters based on status selection
    setFilters({ status: event.target.value });
  };

  console.log("filterValues", filterValues);
  const tutorialFilters = [
    <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 8 }} alwaysOn>
      <FormControl variant="filled" style={{ width: 150 }} size="small">
        <InputLabel>{translate("status")}</InputLabel>
        <Select value={filterValues?.status ?? ""} onChange={handleStatusChange}>
          <MenuItem value="DRAFT">{translate("DRAFT")}</MenuItem>
          <MenuItem value="PUBLISHED">{translate("PUBLISHED")}</MenuItem>
          <MenuItem value="REVIEW">{translate("REVIEW")}</MenuItem>
          <MenuItem value="REMOVED">{translate("REMOVED")}</MenuItem>
        </Select>
      </FormControl>
    </div>,
  ];

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <List {...props} filters={tutorialFilters}>
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="status" />
            <TextField source="category" />
            <BooleanField source="version" addLabel />
          </Datagrid>
        </List>
      )}
    </>
  );
}

export default TutorialList;
