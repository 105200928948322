import {
  BooleanInput,
  Create,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  TopToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function RoleCreate(props) {
  const classes = useStyles();
  const Actions = () => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
      </TopToolbar>
    );
  };
  return (
    <Create {...props} actions={<Actions />}>
      <SimpleForm redirect="list">
        <TextInput source="name" label="name" />
        <BooleanInput source="isAdmin" label="isAdmin" />
        <ReferenceArrayInput source="permissions" reference="Permission" label="permissions" fullWidth>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export default RoleCreate;
