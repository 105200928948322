import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  EditButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function UserShow(props) {
  const classes = useStyles();
  const Actions = ({ basePath, data }) => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };
  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="information">
          <TextField source="name" label="name" />
          <TextField source="email" label="email" />
          <ImageField source="avatar.url" label="avatar" />

          <ReferenceField label="organization" source="organization" reference="Organization" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceArrayField label="roles" reference="Role" source="roles">
            <Datagrid>
              <TextField source="name" label="name" />
              <BooleanField source="isAdmin" label="isAdmin" />
              <ReferenceArrayField label="permissions" reference="Permission" source="permissions">
                <Datagrid>
                  <TextField source="name" />
                </Datagrid>
              </ReferenceArrayField>
            </Datagrid>
          </ReferenceArrayField>
          <BooleanField source="isSuperAdmin" label="isSuperAdmin" />
          <BooleanField source="verified" label="verified" />
          <BooleanField source="tosAccepted" label="tosAccepted" />
          <TextField source="locale" label="locale" />
          <TextField source="link" label="link" />
          <TextField source="backpackId" label="backpackId" />
          <BooleanField source="readOnly" label="readOnly" />
          <NumberField source="emailFrequency" label="emailFrequency" />
          <TextField source="type" label="type" />
          <DateField source="createdAt" label="createdAt" />
          <DateField source="visitedAt" label="visitedAt" />
          <DateField source="updatedAt" label="updatedAt" />
        </Tab>
        <Tab label="asStudent">
          <ReferenceField label="classes" source="class" reference="Class" link="show">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="studentNumber" label="studentNumber" />
          <ReferenceArrayField label="enrolledCourses" source="enrolledCourses" reference="Course" link="show">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>
        <Tab label="asTeacher">
          <ReferenceField label="classes" source="class" reference="Class" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceArrayField label="taughtCourses" source="taughtCourses" reference="Course">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default UserShow;
