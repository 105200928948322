import { useEffect, useRef, useState, useCallback } from "react";
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  email,
  regex,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import BackButton from "../../components/backButton";
import EmailInput from "../../components/user/EmailInput";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function UserCreate(props) {
  const globalOrganization = useSelector((state) => state.organization.value);
  const classes = useStyles();
  const translate = useTranslate();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [domainIndex, setDomainIndex] = useState(0);
  const { source, ...rest } = props;
  const domainRef = useRef();
  domainRef.current = selectedOrg?.emailDomains[domainIndex] ?? "";

  useEffect(() => {
    if (globalOrganization.id !== 0) {
      setSelectedOrg(globalOrganization);
    }
  }, [globalOrganization]);

  const transform = (data) => {
    const { organization } = data;
    const roles = data.roles?.map((roleId) => ({
      id: roleId,
    }));
    const organizationId = globalOrganization.id !== 0 ? globalOrganization.id : organization;
    return { ...data, email: data.email + domainRef.current, roles, organization: organizationId };
  };

  const selectDomainIndex = (event) => {
    const newDomain = event.target.value;
    setDomainIndex(newDomain);
  };
  const emailValidation = () => {
    const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9-_.]*[a-zA-Z0-9]$|^[a-zA-z0-9]$/;

    if (domainRef.current) {
      return regex(emailRegex, `${translate("invalidEmailFormatMessage")}`);
    } else {
      return email(`${translate("invalidEmailFormatMessage")}`);
    }
  };

  const Actions = () => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
      </TopToolbar>
    );
  };

  const MySelectInput = (inputProps) => {
    const { choices, input } = inputProps;
    const curOrganization = choices.filter((choice) => choice.id === input.value)[0];
    // Reset the domain index to 0 when select the different organization.
    if (selectedOrg?.id !== curOrganization?.id) {
      setDomainIndex(0);
    }
    setSelectedOrg(curOrganization);

    return <SelectInput {...inputProps} optionText="name" />;
  };

  return (
    <Create {...props} transform={transform} actions={<Actions />}>
      <SimpleForm>
        {globalOrganization.id === 0 && (
          <ReferenceInput
            reference="Organization"
            source="organization"
            label="Organization"
            allowEmpty
            perPage={99999}
          >
            <MySelectInput />
          </ReferenceInput>
        )}
        <TextInput source="name" label="name" validate={required()} />
        <EmailInput
          source="email"
          emailDomains={selectedOrg?.emailDomains}
          domainIndex={domainIndex}
          selectDomainIndex={selectDomainIndex}
          validate={[required({ message: `${translate("requiredMessage")}` }), emailValidation()]}
          {...rest}
        />
        <TextInput
          source="password"
          label="password"
          type="password"
          validate={minLength(6, "Password needs to be at least 6 characters long")}
        />
        <ReferenceArrayInput source="roles" reference="Role" label="roles">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="isSuperAdmin" label="isSuperAdmin" />
        <BooleanInput source="verified" label="verified" />
        {/* <ImageInput source="avatar">
          <ImageField source="url" />
        </ImageInput> */}
        <SelectInput
          source="locale"
          label="locale"
          choices={[
            { id: "EN", name: "EN" },
            { id: "ZH_HANS", name: "ZH_HANS" },
            { id: "ZH_HANT", name: "ZH_HANT" },
          ]}
        />
        <NumberInput source="emailFrequency" label="emailFrequency" />
        <NumberInput source="type" label="type" />
        <TextInput source="link" label="link" />
        <TextInput source="backpackId" label="backpackId" />
        <BooleanInput source="readOnly" label="readyOnly" />
      </SimpleForm>
    </Create>
  );
}

export default UserCreate;
