import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  NumberField,
  ArrayField,
  ReferenceArrayField,
  Show,
  Tab,
  TabbedShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  EditButton,
  useShowController,
} from "react-admin";
import { makeStyles } from "@material-ui/styles";
import ScalarArrayChipField from "../../components/scalarChip";
import MemberList from "../../components/organization/MemberList";
import BackButton from "../../components/backButton";

const useStyles = makeStyles({
  actions: { justifyContent: "space-between" },
});

function OrganizationShow(props) {
  const { record } = useShowController(props);
  const classes = useStyles();

  const Actions = ({ basePath, data }) => {
    return (
      <TopToolbar className={classes.actions}>
        <BackButton />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="information">
          <NumberField source="id" label="id" />
          <TextField source="name" label="name" />
          <TextField source="code" label="code" />
          <ArrayField source="emailDomains" label="emailDomains">
            <SingleFieldList linkType={false}>
              <ScalarArrayChipField />
            </SingleFieldList>
          </ArrayField>
          <BooleanField source="internal" label="internal" />
          <DateField source="createdAt" label="createdAt" />
          <BooleanField label="active" source="active" />
          <DateField source="subscriptionExpiryDate" label="subscriptionExpiryDate" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default OrganizationShow;
