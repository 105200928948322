import { gql } from "@apollo/client";
import buildDataProvider from "@ra-data-prisma/dataprovider";
import client from "./apolloClient";

const DELETE_TAUGHT_COURSES = gql`
  mutation DeleteManyTaughtCourse($where: TaughtCourseWhereInput) {
    deleteManyTaughtCourse(where: $where) {
      count
    }
  }
`;

const UPDATE_CLASS = gql`
  mutation UpdateOneClass($data: ClassUpdateInput!, $where: ClassWhereUniqueInput!) {
    updateOneClass(data: $data, where: $where) {
      id
    }
  }
`;

const DELETE_DOMAINS = gql`
  mutation DeleteEmailDomains($orgId: Int!, $domains: [EmailDomainWhereUniqueInput!]) {
    updateOneOrganization(where: { id: $orgId }, data: { emailDomains: { delete: $domains } }) {
      id
    }
  }
`;

const ADD_DOMAINS = gql`
  mutation AddEmailDomains($orgId: Int!, $domains: [EmailDomainCreateWithoutOrganizationInput!]) {
    updateOneOrganization(where: { id: $orgId }, data: { emailDomains: { create: $domains } }) {
      id
    }
  }
`;

const DELETE_EMAIL_DOMAINS = gql`
  mutation DeleteEmailDomains($orgId: Int!) {
    updateOneOrganization(
      where: { id: $orgId }
      data: { emailDomains: { deleteMany: { organizationId: { equals: $orgId } } } }
    ) {
      id
    }
  }
`;

export const getDataProvider = async () => {
  const handleCourseUpdate = async (params) => {
    const { teachers } = params.data;
    const { teachers: prevTeachers } = params.previousData;
    params.previousData.taughtCourses = [];

    if (prevTeachers?.length > 0) {
      const removedTaughtCourses = prevTeachers.reduce((acc, prevTeacherId) => {
        if (!teachers.includes(prevTeacherId)) {
          acc.push({
            courseId: { equals: params.data.id },
            teacherId: { equals: prevTeacherId },
          });
        }
        return acc;
      }, []);
      await client.mutate({
        mutation: DELETE_TAUGHT_COURSES,
        variables: {
          where: {
            OR: removedTaughtCourses,
          },
        },
      });
    }
  };

  const handleClassUpdate = async (params) => {
    const { id, students } = params.data;
    const { students: prevStudents } = params.previousData;

    let createdStudents = [];
    if (students?.length > 0) {
      createdStudents = students.reduce((acc, studentId) => {
        if (!prevStudents.includes(studentId)) {
          acc.push({
            where: { userId: studentId },
            create: { userId: studentId },
          });
        }
        return acc;
      }, []);
    }

    let deletedStudents = [];
    if (prevStudents?.length > 0) {
      deletedStudents = prevStudents.reduce((acc, prevStudentId) => {
        if (!students.includes(prevStudentId)) {
          acc.push({
            userId: prevStudentId,
          });
        }
        return acc;
      }, []);
    }

    if (createdStudents.length > 0 || deletedStudents.length > 0) {
      await client.mutate({
        mutation: UPDATE_CLASS,
        variables: {
          where: { id },
          data: {
            students: {
              ...(createdStudents.length > 0 ? { connectOrCreate: createdStudents } : {}),
              ...(deletedStudents.length > 0 ? { disconnect: deletedStudents } : {}),
            },
          },
        },
      });
    }

    params.data.students = [];
    params.previousData.students = [];
  };

  const handleOrganizationDeleteMany = async (params) => {
    const { ids } = params;
    await Promise.all(
      ids.map((id) =>
        client.mutate({
          mutation: DELETE_EMAIL_DOMAINS,
          variables: {
            orgId: id,
          },
        }),
      ),
    );
  };

  const handleOrganizationDelete = async (params) => {
    const { id } = params;
    await client.mutate({
      mutation: DELETE_EMAIL_DOMAINS,
      variables: {
        orgId: id,
      },
    });
  };

  const handleOrganizationUpdate = async (params) => {
    const { emailDomains, id } = params.data;
    const { emailDomains: prevEmailDomains } = params.previousData;
    const deletedDomains = [];
    const addedDomains = [];
    emailDomains.forEach((domain) => {
      if (!prevEmailDomains.includes(domain)) {
        addedDomains.push({ domain });
      }
    });
    prevEmailDomains.forEach((domain) => {
      if (!emailDomains.includes(domain)) {
        deletedDomains.push({ domain });
      }
    });
    if (deletedDomains.length > 0) {
      await client.mutate({
        mutation: DELETE_DOMAINS,
        variables: {
          orgId: id,
          domains: deletedDomains,
        },
      });
    }
    if (addedDomains.length > 0) {
      await client.mutate({
        mutation: ADD_DOMAINS,
        variables: {
          orgId: id,
          domains: addedDomains,
        },
      });
    }
  };

  try {
    const provider = await buildDataProvider({
      client,
      resourceViews: {
        Organization: {
          resource: "Organization",
          fragment: {
            many: {
              type: "blacklist",
              fields: ["members"],
            },
          },
        },
        User: {
          resource: "User",
          fragment: {
            many: {
              type: "blacklist",
              fields: ["myProjects", "assignedAssignments", "assignedTutorials", "authoredTutorials"],
            },
          },
        },
      },
      customizeInputData: {
        Organization: {
          create: (data, params) => ({
            ...data,
            emailDomains: {
              create: params.emailDomains?.map((domain) => ({ domain })),
            },
          }),
          update: (data) => {
            const { emailDomains, ...otherData } = data;
            return {
              ...otherData,
            };
          },
        },
      },
    });
    return async (fetchType, resource, params) => {
      if (params?.id) {
        params.id = Number(params.id);
      }

      if (resource === "Course") {
        if (fetchType === "UPDATE") {
          await handleCourseUpdate(params);
        }
      }

      if (resource === "Class") {
        if (fetchType === "UPDATE") {
          await handleClassUpdate(params);
        }
      }

      if (resource === "Organization") {
        if (fetchType === "UPDATE") {
          await handleOrganizationUpdate(params);
        }
        if (fetchType === "DELETE_MANY") {
          await handleOrganizationDeleteMany(params);
        }
        if (fetchType === "DELETE") {
          await handleOrganizationDelete(params);
        }
      }

      return provider(fetchType, resource, params);
    };
  } catch (error) {
    console.error(error.message);
  }
};
