import {
  BooleanField,
  Datagrid,
  List,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  useTranslate,
  useListController,
  Loading,
} from "react-admin";
import { useSelector } from "react-redux";

function ClassList(props) {
  const { loading } = useListController(props);
  const organization = useSelector((state) => state.organization.value);
  const translate = useTranslate();

  const classFilters = organization.id
    ? []
    : [
        <ReferenceInput source="organizationId" reference="Organization" label="organization" alwaysOn>
          <AutocompleteInput optionText="name" emptyText={translate("all")} />
        </ReferenceInput>,
      ];

  return (
    <List {...props} filters={classFilters} filter={organization.id === 0 ? {} : { organizationId: organization.id }}>
      {loading ? (
        <Loading />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" label="id" />
          <TextField source="name" label="name" />
          <TextField source="year" label="year" />
          <BooleanField source="active" label="active" addLabel />
        </Datagrid>
      )}
    </List>
  );
}

export default ClassList;
