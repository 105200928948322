import { Typography, Button, Dialog, DialogActions, DialogTitle, ButtonGroup } from "@material-ui/core";
import { useLocale, useSetLocale, useTranslate } from "react-admin";

export default function LanguageMenu({ open, handleClose }) {
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();

  const changeLocale = (locale) => {
    setLocale(locale);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: "30px",
        },
      }}
    >
      <DialogTitle>
        <Typography align="center">{translate("localeMenuMessage")}</Typography>
      </DialogTitle>
      <DialogActions>
        <ButtonGroup>
          <Button
            style={{
              color: locale === "en" ? "#fff" : "#2196F3",
              borderColor: "#2196F3",
              backgroundColor: locale === "en" ? "#2196F3" : "#FFFFFF",
            }}
            onClick={() => changeLocale("en")}
          >
            en
          </Button>
          <Button
            style={{
              color: locale === "zh-Hans" ? "#fff" : "#2196F3",
              borderColor: "#2196F3",
              backgroundColor: locale === "zh-Hans" ? "#2196F3" : "#FFFFFF",
            }}
            onClick={() => changeLocale("zh-Hans")}
          >
            zh-hans
          </Button>
          <Button
            style={{
              color: locale === "zh-Hant" ? "#fff" : "#2196F3",
              borderColor: "#2196F3",
              backgroundColor: locale === "zh-Hant" ? "#2196F3" : "#FFFFFF",
            }}
            onClick={() => changeLocale("zh-Hant")}
          >
            zh-hant
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
